





























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CouponCard extends Vue {
  @Prop() coupon

  showOverlay = false

  openCouponModal() {
    this.$root.$emit('openCouponModal', this.coupon)
  }
}
